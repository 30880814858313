import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'
import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import Icon from 'components/shared/icon'
import LazyImage from 'components/shared/lazyImage'
import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

import arrowRightIcon from 'assets/icons/arrow-right-white.svg'

type GalleryItem = {
  img: ImageDataLike
  alt: string
  label: string
  link: string
}

type Props = {
  gallery: GalleryItem[]
}

const Section = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray100};
  padding: 20px 0;

  ${({ theme }) => theme.media.lg.min} {
    padding: 40px 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const StyledContainer = styled(Container)``

const TilesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.media.lg.min} {
    justify-content: center;
  }
`

const BadgeWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 0;
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.primary300};
  padding: 5px 30px 5px 20px;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
`

const TileSingleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.gray300};
  transition: 0.25s;
  margin-bottom: 10px;

  ${({ theme }) => theme.media.lg.min} {
    height: 200px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 250px;
  }

  &:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  ${({ theme }) => theme.media.lg.min} {
    &:nth-child(1) {
      width: 56.5%;
      border-top-right-radius: 0;
      clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);

      & > ${BadgeWrapper} {
        right: auto;
        left: 0;
        padding: 5px 20px 5px 30px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
      }
    }
    &:nth-child(2) {
      width: 56.5%;
      border-top-right-radius: 20px;
      clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);
      margin-left: -13%;
    }
  }
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  // Gatsby image wrapper
  div {
    max-height: 100%;
  }
`

const Tiles: React.FC<Props> = ({ gallery }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <StyledContainer>
        <TilesWrapper>
          {gallery.map(({ img, alt, label, link }) => (
            <TileSingleWrapper as={Link} to={link} key={label}>
              <ImgWrapper>
                <LazyImage
                  src={img}
                  alt={alt}
                  objectFit={lg ? 'contain' : 'cover'}
                />
              </ImgWrapper>
              <BadgeWrapper>
                <Text
                  size={lg ? 24 : 30}
                  weight={500}
                  themecolor="white"
                  dangerouslySetInnerHTML={{ __html: label }}
                  margin="0"
                />
                <Icon src={arrowRightIcon} alt="arrow-right" />
              </BadgeWrapper>
            </TileSingleWrapper>
          ))}
        </TilesWrapper>
      </StyledContainer>
    </Section>
  )
}

export default Tiles
