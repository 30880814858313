import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

import { Colors } from 'types/theme'

type Props = {
  description1: string
  description2: string
  backgroundDesktop?: keyof Colors
}

const Section = styled.section<{ backgroundColor?: keyof Colors }>`
  width: 100%;
  padding: 40px 0 0;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};

    ${({ backgroundColor }) =>
      backgroundColor &&
      css`
        background-color: ${({ theme }) => theme.colors[backgroundColor]};
        padding: 40px 0 40px;
      `}
  }
`

const StyledContainer = styled(Container)`
  padding-bottom: 40px;
`

const InnerWrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`

const Hr = styled.div`
  width: 80px;
  height: 8px;
  margin: 15px auto 10px auto;
  background-color: ${({ theme }) => theme.colors.primary300};
  border-radius: 3px;

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    margin: 0;
  }
`

const DescriptionWrapper = styled.div`
  position: relative;

  ${({ theme }) => theme.media.lg.min} {
    width: 68%;

    &:last-child {
      width: 28%;
      padding-left: 30px;
    }
  }
`

const FenceAboutMain: React.FC<Props> = ({
  description1,
  description2,
  backgroundDesktop,
}) => {
  const { lg } = useBreakpoint()

  return (
    <Section backgroundColor={backgroundDesktop}>
      <StyledContainer>
        <InnerWrapper>
          <DescriptionWrapper>
            <Text
              size={lg ? 16 : 15}
              weight={500}
              themecolor="black"
              align={lg ? 'left' : 'center'}
              dangerouslySetInnerHTML={{ __html: description1 }}
            />
          </DescriptionWrapper>

          <DescriptionWrapper>
            <Hr />
            <Text
              size={lg ? 16 : 15}
              weight={500}
              themecolor="black"
              align={lg ? 'left' : 'center'}
              dangerouslySetInnerHTML={{ __html: description2 }}
            />
          </DescriptionWrapper>
        </InnerWrapper>
      </StyledContainer>
    </Section>
  )
}

export default FenceAboutMain
